import React, {useState, useEffect} from  'react';
import api from '../../services/api';
import Select from 'react-select';

export default function ContractsTypesMultipleSelect(props){
    
    const [types, setTypes] = useState([]);    
    const [options, setOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState(false);

    
    const onChange = (options) => {    
        props.onChange(options);
    };

    useEffect(() => {                
        if(options.length > 0 && props.defaultValue != undefined){
            let defaultOptions = [];
            options.map((option, index) => { 
                props.defaultValue.map((defaultValue, index) => {
                    if(option.value == defaultValue.id){
                        defaultOptions.push(option);
                    }
                });
            });                   
            setDefaultValue(defaultOptions);            
        } 
    }, [options]);     

    const getOptions = () => {
        let options_arr = [];        
        types.map((item, index) => {
            let option = {
                value: item.id,
                label: item.attributes.name
            };
            options_arr.push(option);
        });
        setOptions(options_arr);     
    };      

    useEffect(() => {
        getOptions();    
    }, [types]);
    
    const getTypes = () => {        
        api.get('/contracts_types/index')
        .then(response => {     
            setTypes(response.data.data);                   
        });         
    }    

    useEffect(() => {        
        getTypes();       
    }, []); 

    return (
        <>
            {(options && defaultValue) && <Select name="contracts_types" isMulti options={options} defaultValue={defaultValue} onChange={onChange} />}
        </>
    );

}