import React, { useContext } from "react";
import { AuthProvider, Context } from './context/AuthContext';
import LoginLayout from "./components/layout/LoginLayout";
import DashboardLayout from "./components/layout/DashboardLayout";
import { Home } from "./components/pages/home";
import { Dashboard } from "./components/pages/dashboard";
import { Issues } from "./components/pages/issues";
import { Activities } from "./components/pages/activities";
import { Equipaments } from "./components/pages/equipaments";
import { Locations } from "./components/pages/locations";
import { Contracts } from "./components/pages/contracts";
import { ContractsEquipaments } from "./components/pages/contracts-equipaments";
import { Settings } from "./components/pages/settings";
import { Users } from "./components/pages/users";
import { isAuthenticated } from "./services/auth";
import { IssueProvider } from './context/IssueContext';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import './App.scss';

export default function Main() {

  return(
    <AuthProvider>
      <Router> 
        <Switch>
          
          <CustomRoute exact path="/" layout={LoginLayout} component={Home} />
          <CustomRoute exact isPrivate path="/dashboard" layout={DashboardLayout} component={Dashboard} /> 
          <CustomRoute exact isPrivate path="/activities" layout={DashboardLayout} component={Activities} />          
          <CustomRoute exact isPrivate path="/equipaments" layout={DashboardLayout} component={Equipaments} />
          <CustomRoute exact isPrivate path="/locations" layout={DashboardLayout} component={Locations} />       
          <CustomRoute isPrivate path="/locations/:location_id/contracts" layout={DashboardLayout} component={Contracts} />       
          <CustomRoute isPrivate path="/contracts/:contract_id/equipaments" layout={DashboardLayout} component={ContractsEquipaments} />       
          <CustomRoute exact isPrivate path="/users" layout={DashboardLayout} component={Users} />

          <IssueProvider>
            <CustomRoute isPrivate path="/issues/:id?" layout={DashboardLayout} component={Issues} />                                    
          </IssueProvider>

        </Switch>
      </Router>
    </AuthProvider>
  )

}

function CustomRoute({ component: Component, layout: Layout, isPrivate, ...rest}){  

  const { loading, authenticated } = useContext(Context);
  if (loading) {
    return <div></div>;
  } 

  if(isPrivate && !authenticated){
    return <Redirect to="/" />
  }
 
  return <Route {...rest} render={(props) => {       
    return (<Layout {...props} component={Component} />)
  }} />

}