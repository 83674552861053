import React, { useEffect, useState, useContext, useRef } from 'react';
import { Context } from '../../../context/AuthContext';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import api from '../../../services/api';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import StatusSelect from '../../ui/status-select';
import UrgenciesSelect from '../../ui/urgencies-select';
import AttendantsSelect from '../../ui/attendants-select';
import BadgeUrgency from '../../ui/badge-urgency';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function IssueView(props){

    const { permissions } = useContext(Context);

    const [blocking, setBlocking] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);    
    const [issue, setIssue] = useState(false);      
    const [showAlert, setShowAlert] = useState(false);

    const showIssueList = () => props.showIssueList();

    const handleStatusFilter = (value) => {
        setBlocking(true);
        let endpoint = "/issues/update";
        api.post(endpoint, {
            id: props.id,
            status_id: value
        }).then(response => {  
            setShowSuccessToast(true);           
            loadIssue();
            setBlocking(false);            
        }).catch((error) => {
            setBlocking(false);
        });          
    };  
    
    const handleUrgencyFilter = (value) => {
        setBlocking(true);
        api.post("/issues/update", {
            id: props.id,
            urgency: value
        }).then(response => {       
            setShowSuccessToast(true);      
            loadIssue();
            setBlocking(false);            
        }).catch((error) => {
            setBlocking(false);
        });        
    };  

    const handleAttendantFilter = (value) => {
        setBlocking(true);
        api.post("/issues/attend", {
            id: props.id,
            attendant_user_id: value
        }).then(response => {          
            setShowSuccessToast(true);   
            loadIssue();
            setBlocking(false);            
        }).catch((error) => {
            setBlocking(false);
        });        
    };      

    const handleAddComment = (e) => {        
        setBlocking(true);   
        const {comment} = e.target.elements;
        api.post("/issues/add_comment", {
            id: props.id,
            observation: comment.value
        }).then(response => {      
            setShowSuccessToast(true);       
            loadIssue();
            setBlocking(false);
            comment.value = '';
        }).catch((error) => {
            setBlocking(false);
        });
        e.preventDefault();
    }; 
    
    const handleIssuePending = () => {
        setShowAlert(false);
        handleStatusFilter(4);
    };

    const handleIssueClose = () => {
        setShowAlert(false);
        handleStatusFilter(5);
    };    

    const handleIssueRequestClosing = () => {
        handleStatusFilter(3);
    };  

    const loadIssue = () => {
        setBlocking(true);
        api.get("/issues/show/"+props.id).then(response => {
            setIssue(response.data.data);
            setBlocking(false);
        });
    };

    useEffect(() => {        
        loadIssue();    
    }, []);   
    
    useEffect(() => {
        if(issue.relationships?.status?.id == 3){
            setShowAlert(true);
        }
    }, [issue]);       

    return(      
        <BlockUi tag="div" className="h-100" blocking={blocking}>  
            {issue &&
                <div className="border">
                    <div className="subheader bg-white border-bottom px-3 py-3 d-flex align-items-center justify-content-between bg-light">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => {showIssueList()}}>
                                <svg width="8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" class="svg-inline--fa fa-arrow-left fa-w-14 text-dark me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                                Chamados
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                ID: #{issue.id}
                            </Breadcrumb.Item>                    
                        </Breadcrumb>
                    </div>
                    <div className="p-3 py-3 py-lg-4 bg-white">
                        <div className="row">
                            <div className="col-xl-8 col-xxl-8 mx-auto">
                                <div className="row mb-3">
                                    <div className="col-lg-12">
                                        <h4 className="mt-lg-3">{issue.attributes.title}</h4>
                                        <div className="small">                                        
                                            {issue.relationships.location.attributes.name} | {issue.relationships.activity.attributes.name}
                                        </div>
                                        {!permissions.includes('full-access') &&
                                            <div className="mt-1">
                                                <BadgeUrgency urgency={issue.attributes.urgency} />
                                                {issue.relationships?.status && 
                                                    <Badge bg={issue.relationships?.status.attributes.css_class} className="m-1">{issue.relationships?.status.attributes.name}</Badge>
                                                }                                            
                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-12">
                                        {permissions.includes('full-access') &&
                                            <div className="row mb-2 pt-3">                                                     
                                                <div className="col-xl-4 mb-2 mb-lg-0">
                                                    <StatusSelect defaultValue={issue.relationships.status.id} label="-- Selecione o status --" onChange={handleStatusFilter} />
                                                </div>                                            
                                                <div className="col-xl-4 mb-2 mb-lg-0">
                                                    <UrgenciesSelect defaultValue={issue.attributes.urgency} label="-- Selecione a urgência --" onChange={handleUrgencyFilter} />                                            
                                                </div> 
                                                <div className="col-xl-4">
                                                    <AttendantsSelect defaultValue={issue.attributes.current_attendant_user_id} label="-- Selecione o técnico --" onChange={handleAttendantFilter} />                                            
                                                </div>                                                                                            
                                            </div>
                                        }                                    
                                    </div>
                                </div>     
                                <div className="row pb-5">                                    
                                    <div className="col-lg-12">

                                        {(permissions.includes('request-closing-issue') && [1,2,4].includes(issue.relationships.status.id)) &&
                                            <Button size="sm" variant="outline-dark" onClick={() => handleIssueRequestClosing()} className="mb-3">
                                                Solicitar encerramento do chamado
                                            </Button>  
                                        }                                        

                                        {permissions.includes('close-issue') &&
                                            <Alert show={showAlert} variant="primary">
                                                <Alert.Heading>Atenção</Alert.Heading>
                                                <p>
                                                    O técnico responsável por essa demanda solicitou o encerramento do chamado.
                                                </p>
                                                <hr />
                                                <Button variant="primary" onClick={() => handleIssueClose()} className="mb-2 me-lg-2 mb-lg-0">
                                                    Ok, encerrar chamado.
                                                </Button>   
                                                <Button variant="outline-danger" onClick={() => handleIssuePending()}>
                                                    Ainda não, há pendências.
                                                </Button>
                                            </Alert>
                                        }

                                        <div className="card pt-3 pb-2 border-secondary">
                                            <div className="card-header bg-white border-0 py-0 d-flex justify-content-between">
                                                <strong className="small">{issue.relationships.author.attributes.name}</strong>
                                                <small className="text-muted">{issue.attributes.beginning}</small>
                                            </div>
                                            <div className="card-body">
                                                {issue.attributes.description}
                                            </div>                                            
                                        </div>  

                                        {issue.relationships.revisions.map((revision, i) => (
                                            <>
                                                <div className="line"></div>
                                                <div className="card pt-3 pb-2 bg-white">
                                                    <div className="card-header bg-transparent border-0 py-0 d-flex justify-content-between">
                                                        <strong className="small">{revision.relationships.author.attributes.name}</strong>
                                                        <small className="text-muted">{revision.attributes.created_at}</small>
                                                    </div>
                                                    <div className="card-body">
                                                        <span dangerouslySetInnerHTML={{__html: revision.attributes.observation}}></span>
                                                    </div>
                                                </div> 
                                            </>                                   
                                        ))}

                                        {issue.relationships?.status?.id != 5 &&
                                            <div className="mt-4">
                                                <Form onSubmit={handleAddComment}>
                                                    <FloatingLabel controlId="floatingTextarea2" label="Comentários">                                            
                                                        <Form.Control name="comment" as="textarea" style={{ height: '150px' }} required />
                                                    </FloatingLabel>
                                                    <Form.Group className="mt-3 d-flex justify-content-end">
                                                        <Button variant="dark" type="submit">
                                                            Enviar
                                                        </Button>
                                                    </Form.Group>
                                                </Form>                                 
                                            </div> 
                                        }                                        
                                    </div>                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <ToastContainer className="p-3" style={{position: 'fixed',top: 0,right: 0, zIndex: 999999}}>
                <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={3000} bg="danger" autohide>
                    <Toast.Header className="bg-danger">
                        <strong className="me-auto text-white">Atenção</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body className="text-white">Houve um erro ao atualizar. Por favor, tente novamente.</Toast.Body>
                </Toast>
                <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={3000} bg="success" autohide>
                    <Toast.Header className="bg-success">
                        <strong className="me-auto text-white">Sucesso!</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body className="text-white">Chamado atualizado com sucesso!</Toast.Body>
                </Toast>                    
            </ToastContainer>               

        </BlockUi>
    );    
}

export default IssueView;