import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import ContractsTypesMultipleSelect from '../../ui/contracts-types-multiple-select';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function ActivitiesForm(props){

  const [blocking, setBlocking] = useState(false);
  const [item, setItem] = useState({});

  const handleContractsTypesFilter = (value) => {
    //
  };  

  const RenderContractsTypesMultipleSelect = () => {
    if(item.relationships){
      return <ContractsTypesMultipleSelect label="-- Selecione os tipos de contrato --" defaultValue={item.relationships.contracts_types} onChange={handleContractsTypesFilter} />;
    }else{
      return <ContractsTypesMultipleSelect label="-- Selecione os tipos de contrato --" defaultValue={[]} onChange={handleContractsTypesFilter} />;
    }  
  }  

  const handleSubmit = (e) => {    

    setBlocking(true);   

    const {name, contracts_types} = e.target.elements;   
    
    let contracts_types_ids = [];
    if(contracts_types.length == undefined){
      if(contracts_types.value){
        contracts_types_ids.push(contracts_types.value);
      }      
    }else{
      contracts_types.forEach((item, index) => {
        contracts_types_ids.push(item.value);
      });
    }    
    
    let params = {
      name: name.value,
      contracts_types_ids: contracts_types_ids
    };

    let endpoint = '/activities/create';
    if(item.id){
      params.id = item.id;
      endpoint = '/activities/update';
    }    

    api.post(endpoint, params)
    .then(response => { 
        props.onHide(true);
        setBlocking(false);
    }).catch((error) => {
        setBlocking(false);
    });
    e.preventDefault();

};

  useEffect(() => {
    if(props.id !== null){
      setBlocking(true);
      api.get('/activities/show/'+props.id)
      .then(response => {     
          setBlocking(false); 
          setItem(response.data.data);               
      });   
    }else{
      setItem({});
    }
  },[props.id]);

  const onHide = () => {
    props.onHide();
  };

  return(
    
      <Modal
        {...props}
        size="lg"
        centered
        onHide={() => onHide()}
      >
        
        <BlockUi tag="div" blocking={blocking}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.id ? 'Editar atividade' : 'Nova atividade'}
              </Modal.Title>
            </Modal.Header>            
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Nome da atividade</Form.Label>
                <Form.Control name="name" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.name} />
              </Form.Group> 
              <Form.Group className="mb-3">
                <Form.Label>Tipos de contrato permitidos</Form.Label>
                <RenderContractsTypesMultipleSelect />                
              </Form.Group>                          
            </Modal.Body>          
            <Modal.Footer>
              <Button type="submit">Salvar</Button>
            </Modal.Footer>
          </Form>
        </BlockUi>
        
      </Modal>
    
  );
}

export default ActivitiesForm;