import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import UsersForm from "./users-form";

function Users(props){
    
  const [blocking, setBlocking] = useState(false);
  const [items, setitems] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [itemId, setItemId] = useState(null);
  
  const openFormModal = (id) => {    
    setItemId(id);    
    setModalShow(true);
  };

  const closeFormModal = (refresh) => {
    setItemId(null);
    setModalShow(false);
    if(refresh){
      loadItems();
    }
  };

  const handleDelete = (id) => {
    if(window.confirm('Are you sure?')){
      setBlocking(true);
      api.post('/users/delete', {id: id})
      .then(response => {
          loadItems();
          setBlocking(false);                     
      })
      .catch(err => {
        alert('Houve um erro, por favor tente novamente.');
        setBlocking(false);
      });    
    }
  };

  const loadItems = () => {
    setBlocking(true);
    api.get('/users/index')
    .then(response => {
        setBlocking(false); 
        setitems(response.data.data);               
    });    
  };    

  useEffect(() => {
    loadItems();
  }, []);   

  return(
    <BlockUi tag="div" blocking={blocking} className="bg-light">
      <div className="border">
        <div className="subheader bg-white border-bottom px-3 py-3 d-flex align-items-center justify-content-between bg-light">
          <h2 className="h5 mb-0">Usuários</h2>
          <div className="ms-3">
              <a href="#" className="btn btn-outline-primary" onClick={() => openFormModal(null)}>+ Novo usuário</a>
          </div>                
        </div>
        <div>
          {items && items.map((item, index) => (
            <div className="card p-3 border-0 border-bottom rounded-0">
              <div className="row align-items-center">
                <div className="col">
                  {item.attributes.name}
                </div>
                <div className="col-auto text-end">
                  <Button variant="primary" onClick={() => openFormModal(item.id)}>Editar</Button>
                  <Button variant="danger" onClick={() => handleDelete(item.id)} className="ms-2">Delete</Button>
                </div>                
              </div>              
            </div>
          ))}          
        </div>        
      </div>
      <UsersForm
        show={modalShow}
        id={itemId}
        onHide={closeFormModal}
      />                
    </BlockUi>
  );

}

export default Users;