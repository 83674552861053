import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { useIssue } from "../../../context/IssueContext";

function IssueSidebar(props){    

    const {       
      searchParams, setSearchParams
    } = useIssue();  

    const search = (e) => {  
      const {term} = e.target.elements; 
      let newSearchParams = {};
      newSearchParams.term = term.value;        
      newSearchParams = {...searchParams, ...newSearchParams};       
      setSearchParams(newSearchParams);
      e.preventDefault();
    };    

    const status = (status_id) => {
      let newSearchParams = {};
      newSearchParams.current_status_id = status_id;        
      newSearchParams = {...searchParams, ...newSearchParams};       
      setSearchParams(newSearchParams);          
    }

    return(
      <div className="issues-sidebar bg-light sticky-top">
        <div className="subheader bg-white px-3 border-bottom d-flex align-items-center justify-content-between">
          <h2 className="h5 mb-0">Chamados</h2>
          <div>
            <a href="#" className="btn btn-outline-dark btn-sm" onClick={() => props.showIssueForm(null)}>+ Novo chamado</a>
          </div>
        </div>
        <div className="p-3">
          <Form onSubmit={search}>
            <InputGroup className="mb-3">
              <FormControl name="term" className="border-secondary" placeholder="Digite o termo..." />
              <Button variant="outline-secondary" type="submit">
                Search
              </Button>
            </InputGroup>     
          </Form>               
        </div>
        <div className="px-4 mb-5">       
          <Nav className="flex-column pr-5">
            <Nav.Item>
                <Nav.Link onClick={() => status(null)} className={"px-0 " + (searchParams?.current_status_id == null && 'font-weight-bold text-dark active')}>
                  <span className="circle bg-dark"></span>
                  Todos
                </Nav.Link>
            </Nav.Item> 
            <Nav.Item>                 
                <Nav.Link onClick={() => status(1)} className={"px-0 " + (searchParams?.current_status_id == 1 && 'font-weight-bold text-warning active')}>
                  <span className="circle bg-warning"></span>
                  Em aberto
                </Nav.Link>
            </Nav.Item> 
            <Nav.Item>                 
                <Nav.Link onClick={() => status(2)} className={"px-0 " + (searchParams?.current_status_id == 2 && 'font-weight-bold text-primary active')}>
                  <span className="circle bg-primary"></span>
                  Em andamento
                </Nav.Link>
            </Nav.Item>                                                     
            <Nav.Item>                    
                <Nav.Link onClick={() => status(3)} className={"px-0 " + (searchParams?.current_status_id == 3 && 'font-weight-bold text-info active')}>
                  <span className="circle bg-info"></span>
                  Aguardando encerramento
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>                    
                <Nav.Link onClick={() => status(4)} className={"px-0 " + (searchParams?.current_status_id == 4 && 'font-weight-bold text-success active')}>
                  <span className="circle bg-success"></span>
                  Encerrados
                </Nav.Link>
            </Nav.Item>               
          </Nav>                     
        </div>
        <div className="px-4 d-none">       
          <Nav className="flex-column">
            <Nav.Item>
              <div className="text-uppercase text-secondary small mb-3">Período</div> 
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/" className="px-0">Desde o início</Nav.Link>
            </Nav.Item>                                   
            <Nav.Item>
                <Nav.Link href="/" className="px-0">Últimos 7 dias</Nav.Link>
            </Nav.Item> 
            <Nav.Item>                 
                <Nav.Link href="/dashboard" className="px-0">Últimos 15 dias</Nav.Link>
            </Nav.Item> 
            <Nav.Item>                 
                <Nav.Link href="/dashboard" className="px-0">Nesse mês</Nav.Link>
            </Nav.Item>              
          </Nav>                     
        </div>                  
      </div>
    );    
}

export default IssueSidebar;