import { HeaderNavbar } from "../../components/elements";

const DashboardLayout = ({ component: Component, ...rest }) => {
  
  return (
    <>        
      <header className="bg-dark shadow-sm mb-4 py-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <HeaderNavbar />
            </div>
          </div>
        </div>
      </header>
      <main className="pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Component {...rest} />
            </div>
          </div>
          </div>
      </main>
    </>
  );
};

export default DashboardLayout;