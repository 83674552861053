import React, { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Context } from '../../../context/AuthContext';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'

function Home(props){

  const [blocking, setBlocking] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);  
  const { authenticated, handleLogin } = useContext(Context);
  const history = useHistory();

  const login = (e) => {
    setBlocking(true);
    const {email, password} = e.target.elements;
    const callback = handleLogin(email.value, password.value);
    callback.then((response) => {
      setBlocking(false);
      if(response){
        history.push('/dashboard');
      }else{
        setShowErrorToast(true);
      }
    });
    e.preventDefault();

  };

  return(
    <BlockUi tag="div" blocking={blocking} className="h-100">
      <div className="form-login h-100 bg-light p-5 mx-auto d-flex align-items-center justify-content-center">      
          <Form onSubmit={login}>
            <h1 className="mb-4">WNA</h1>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Seu e-mail</Form.Label>
              <Form.Control name="email" size="lg" type="email" placeholder="Digite seu e-mail" style={{width: '300px'}} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Sua senha</Form.Label>
              <Form.Control name="password" size="lg" type="password" placeholder="Digite sua senha"  style={{width: '300px'}} />
            </Form.Group>        
            <Button variant="primary" size="lg" type="submit" className="w-100">
              Entrar
            </Button>        
          </Form>           
      </div>
      <ToastContainer className="p-3" style={{position: 'fixed',top: 0,right: 0, zIndex: 999999}}>
          <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={3000} bg="danger" autohide>
              <Toast.Header className="bg-danger">
                  <strong className="me-auto text-white">Usuário não encontrado</strong>
                  <small></small>
              </Toast.Header>
              <Toast.Body className="text-white">Por favor confira suas credenciais.</Toast.Body>
          </Toast>                 
      </ToastContainer>        
    </BlockUi>
  );

}

export default Home;