import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import api from '../../services/api';
import jwt_decode from "jwt-decode";

export const DOMAIN = "wna";
export const TOKEN_KEY = "@wna-token";
export const PROFILE = "@wna-profile";

export default function useAuth() {

  const history = useHistory();
    
  const [authenticated, setAuthenticated] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const [loading, setLoading] = useState(true);

  const setAuthData = (token) => {
    setAuthenticated(true);
    var decoded = jwt_decode(token);
    setPermissions(decoded.permissions);
  }

  const removeAuthData = () => {
    setAuthenticated(false);
    setPermissions(false);
  }  

  useEffect(() => {
    const token = getToken(); 
    if (token) {
      setAuthData(token);      
    }
    setLoading(false);
  }, []);
  
  async function handleLogin(email, password) {    
    try {
        const response = await api.post("/auth/login", { email, password });
        if(response.data.access_token !== undefined && response.data.access_token !== null){
            localStorage.setItem(TOKEN_KEY, response.data.access_token);    
            setAuthData(response.data.access_token);
            return true;              
        }else{
          return false;
        }            
    } catch (error) {
      return false;
    }
  }

  function handleLogout() {      
    removeAuthData();
    localStorage.removeItem(TOKEN_KEY);    
  }

  function getToken(){
    const token = localStorage.getItem(TOKEN_KEY); 
    return token;
  }
  
  return { authenticated, permissions, loading, handleLogin, handleLogout, getToken };
}