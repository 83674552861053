
const LoginLayout = ({ component: Component, ...rest }) => {
  
  return (
    <main style={{height: '100vh'}}>
      <div className="container-fluid p-0 h-100">
        <div className="row g-0 h-100">
          <div className="col-xl">
            <Component {...rest} />
          </div>
          <div className="col-xl bg-dark d-none d-lg-block">
            
          </div>          
        </div>
      </div>
    </main>
  );
};

export default LoginLayout;