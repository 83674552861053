import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import BlockUi from 'react-block-ui';
import PeriodsSelect from '../../ui/periods-select';
import LocationsSelect from '../../ui/locations-select';
import ActivitiesSelect from '../../ui/activities-select';
import UrgenciesSelect from '../../ui/urgencies-select';
import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

function Dashboard(){

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const [blocking, setBlocking] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [issuesByStatus, setIssuesByStatus] = useState(false);
  const [issuesBySla, setIssuesBySla] = useState(false); 
  const [issuesGroupByDay, setIssuesGroupByDay] = useState(false);

  const handlePeriod = (value) => {
    let newSearchParams = {};
    newSearchParams.period = value;
    setNewSearchParams(newSearchParams);     
  };

  const handleCustomDates = (date_from, date_until) => {
    let newSearchParams = {};
    newSearchParams.date_from = date_from;
    newSearchParams.date_until = date_until;
    setNewSearchParams(newSearchParams); 
  };    

  const handleLocationFilter = (value) => {
    let newSearchParams = {};
    newSearchParams.location_id = value;
    newSearchParams.activity_id = null;        
    setNewSearchParams(newSearchParams); 
  };

  const handleActivityFilter = (value) => {
    let newSearchParams = {};
    newSearchParams.activity_id = value;        
    setNewSearchParams(newSearchParams);          
  };     

  const handleUrgencyFilter = (value) => {
    let newSearchParams = {};
    newSearchParams.urgency = value;   
    setNewSearchParams(newSearchParams);  
  };

  const setNewSearchParams = (newSearchParams) => {        
    setSearchParams({...searchParams, ...newSearchParams});  
  };  

  const loadStatistics = () => { 
    setBlocking(true);
    api.get('/issues/statistics', {
      params: searchParams
    })
    .then(response => {
      setBlocking(false);
      setIssuesByStatus(response.data.data.issues_by_status); 
      setIssuesBySla(response.data.data.issues_by_sla);
      setIssuesGroupByDay(response.data.data.issues_group_by_day);
    });
  };

  useEffect(() => {
    loadStatistics();
  }, [searchParams]);   

  useEffect(() => {
    let newSearchParams = {};
    newSearchParams.period = 'begin';
    setNewSearchParams(newSearchParams);      
  }, []);

  return(
    <BlockUi tag="div" blocking={blocking} className="bg-light">

      <div className="border border-bottom-0">
        <div className="subheader bg-white px-3 py-3 d-lg-flex align-items-center bg-light">
          <h2 className="h5 mb-3 mb-lg-0">Dashboard</h2>  
          <PeriodsSelect defaultValue="begin" onChange={handlePeriod} onChangeCustomDates={handleCustomDates} />
        </div>
      </div>
      <div className="border bg-white">
          <div className="card p-3 border-0 border-bottom rounded-0 bg-white">
              <div className="row align-items-center">                   
                  <div className="col-xl col-xxl mb-2 mb-lg-0">
                      <LocationsSelect label="-- Qualquer unidade --" onChange={handleLocationFilter} />
                  </div>
                  <div className="col-xl col-xxl mb-2 mb-lg-0">
                      <ActivitiesSelect label="-- Qualquer atividade --" location={searchParams.location_id} onChange={handleActivityFilter} />
                  </div>
                  <div className="col-xl col-xxl mb-2 mb-lg-0">
                      <UrgenciesSelect label="-- Qualquer urgência --" onChange={handleUrgencyFilter} />
                  </div>                                 
              </div>
          </div>
      </div>
      <div> 
        <div className="row">
          <div className="col-lg-6">
            {issuesByStatus && 
              <div className="card p-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="h5 mb-0">Chamados por status</h2>
                </div>          
                <hr />      

                <div style={{ width: '100%', height: 500}}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie dataKey="value" data={issuesByStatus} fill="#8884d8" label isAnimationActive={false}>
                        {issuesByStatus.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>                  
                </div>

              </div> 
            } 
          </div>
          <div className="col-lg-6">
            {issuesBySla && 
              <div className="card p-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="h5 mb-0">SLA</h2>
                </div>          
                <hr />

                <div style={{ width: '100%', height: 500}}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie dataKey="value" data={issuesBySla} fill="#8884d8" label isAnimationActive={false}>
                        {issuesBySla.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>                  
                </div>
                                      
              </div>  
            }             
          </div>  

          {issuesGroupByDay &&
            <div className="col-lg-12">
              <div className="card p-3 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="h5 mb-0">Números de chamados</h2>
                </div>          
                <hr />            
                <div style={{ width: '100%', height: 500}}>
                  <ResponsiveContainer>
                    <LineChart
                      data={issuesGroupByDay}
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 20,
                      }}
                      isAnimationActive={false}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line name="Número de chamados" type="Function" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />                      
                    </LineChart>
                  </ResponsiveContainer>          
                </div>
              </div>
            </div>
          }

        </div>      

      </div>
    </BlockUi>
  );

}

export default Dashboard;