import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function EquipamentsForm(props){

  const [blocking, setBlocking] = useState(false);
  const [item, setItem] = useState({});

  const handleSubmit = (e) => {    

    setBlocking(true);   

    const {name} = e.target.elements;  
    
    let params = {
      name: name.value
    };

    let endpoint = '/equipaments/create';
    if(item.id){
      params.id = item.id;
      endpoint = '/equipaments/update';
    }    

    api.post(endpoint, params)
    .then(response => { 
        props.onHide(true);
        setBlocking(false);
    }).catch((error) => {
        setBlocking(false);
    });
    e.preventDefault();

};

  useEffect(() => {
    if(props.id !== null){
      setBlocking(true);
      api.get('/equipaments/show/'+props.id)
      .then(response => {     
          setBlocking(false); 
          setItem(response.data.data);               
      });   
    }else{
      setItem({});
    }
  },[props.id]);

  const onHide = () => {
    props.onHide();
  };

  return(
    
      <Modal
        {...props}
        size="lg"
        centered
        onHide={() => onHide()}
      >
        
        <BlockUi tag="div" blocking={blocking}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.id ? 'Editar equipamento' : 'Novo equipamento'}
              </Modal.Title>
            </Modal.Header>            
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Nome do equipamento</Form.Label>
                <Form.Control name="name" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.name} />
              </Form.Group>                         
            </Modal.Body>          
            <Modal.Footer>
              <Button type="submit">Salvar</Button>
            </Modal.Footer>
          </Form>
        </BlockUi>
        
      </Modal>
    
  );
}

export default EquipamentsForm;