import React, {useState, useEffect} from  'react';
import api from '../../services/api';
import Select from 'react-select';

export default function UrgenciesSelect(props){

    const [urgencies, setUrgencies] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [options, setOptions] = useState([]);

    const onChange = (option) => {        
        props.onChange(option.value);
    };

    const getUrgencies = () => {
        api.get('/issues/get_urgency_list')
        .then(response => {     
            setUrgencies(response.data.data);                   
        });         
    }

    const getOptions = () => {
        let options = [
            {value: null, label: (props.label ? props.label : '-- Selecione --')} 
        ];        
        urgencies.map((item, index) => {
            let option = {
                value: item.id,
                label: item.attributes.name
            };
            options.push(option);
        });
        setOptions(options);         
    };   

    useEffect(() => {        
        getUrgencies();
    }, []);

    useEffect(() => {
        getOptions();
    }, [urgencies]);   
    
    useEffect(() => {
        options.map((option, index) => {            
            if(option.value == props.defaultValue){
                setDefaultValue(option);                
            }            
        });
    }, [options]);

    return (
        <>
            {(options && defaultValue) && <Select options={options} defaultValue={defaultValue} onChange={onChange} />}
        </>
    );

}