import React, {useState, useEffect} from  'react';
import api from '../../services/api';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

export default function PeriodsSelect(props){

    const [periods, setPeriods] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [options, setOptions] = useState([]);    
    const [dateFrom, setDateFrom] = useState(null);
    const [dateUntil, setDateUntil] = useState(null);
  
    const handleCustomDatesSubmit = (e) => {
        const {date_from, date_until} = e.target.elements;
        props.onChangeCustomDates(date_from.value, date_until.value);
        e.preventDefault();
    };

    const onChange = (option) => {  
        setDefaultValue(option.value);      
        props.onChange(option.value);
    };

    const getPeriods = () => {
        setPeriods([
            {
                value: 'begin',
                label: 'Desde o início'
            },            
            {
                value: 'today',
                label: 'Hoje'
            },
            {
                value: 'last_15_days',
                label: 'Últimos 15 dias'
            },
            {
                value: 'last_30_days',
                label: 'Últimos 30 dias'
            },
            {
                value: 'custom',
                label: 'Personalizado'
            }                
        ]);        
    }

    const getOptions = () => {
        let options = [];        
        periods.map((item, index) => {
            let option = {
                value: item.value,
                label: item.label
            };
            options.push(option);
        });
        setOptions(options);         
    };   

    useEffect(() => {        
        getPeriods();
    }, []);

    useEffect(() => {
        getOptions();
    }, [periods]);   
    
    useEffect(() => {
        options.map((option, index) => {            
            if(option.value == props.defaultValue){
                setDefaultValue(option);                
            }
        });
    }, [options]);

    return (
        <Form onSubmit={handleCustomDatesSubmit} className="ms-auto">
            {(options && defaultValue) && 
                <div className="row g-1 ms-auto">
                    
                    <div className="col-lg-4">
                        <div style={{minWidth: '200px'}}>
                            <Select options={options} defaultValue={defaultValue} onChange={onChange} />
                        </div>
                    </div>

                    {defaultValue == 'custom' &&
                        <div className="col-lg">
                            <InputGroup>
                                <InputGroup.Text>De</InputGroup.Text>
                                <FormControl name="date_from" type="date" defaultValue={dateFrom} />
                            </InputGroup>             
                        </div>
                    }

                    {defaultValue == 'custom' &&
                        <div className="col-lg">
                            <InputGroup>
                                <InputGroup.Text>Até</InputGroup.Text>
                                <FormControl name="date_until" type="date" defaultValue={dateUntil} />
                            </InputGroup>              
                        </div>   
                    } 

                    {defaultValue == 'custom' &&
                        <div className="col-lg-auto">
                            <Button type="submit" className="d-block d-lg-inline-block w-100">Ok</Button>
                        </div>
                    }

                </div>            
            }
        </Form>
    );

}