import React, {useState, useEffect} from  'react';
import api from '../../services/api';
import Select from 'react-select';

export default function ActivitiesSelect(props){
    
    const [isDisabled, setIsDisabled] = useState(true);
    const [activities, setActivities] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [options, setOptions] = useState([]);

    const onChange = (option) => {        
        props.onChange(option.value);
    };

    const getActivities = () => {
        api.get('/activities/index', {
            params: {
                location_id: props.location
            }
        })
        .then(response => {     
            setActivities(response.data.data);                   
        });         
    }

    const getOptions = () => {
        let options = [
            {value: null, label: (props.label ? props.label : '-- Selecione --')} 
        ];        
        activities.map((item, index) => {
            let option = {
                value: item.id,
                label: item.attributes.name
            };
            options.push(option);
        });
        setOptions(options);        
    };   

    useEffect(() => {
        getOptions();
    }, [activities]);   
    
    useEffect(() => {
        options.map((option, index) => {            
            if(option.value == props.defaultValue){
                setDefaultValue(option);                
            }
        });       
    }, [options]);  

    useEffect(() => {  
        if(props.location !== undefined && props.location !== null){            
            getActivities();
            setIsDisabled(false);
        }else{
            setActivities([]);
            setOptions([]);
            setDefaultValue(false);
            setIsDisabled(true);
        }        
    }, [props.location]); 

    return (
        <>
            {(options && defaultValue) && <Select options={options} defaultValue={defaultValue} onChange={onChange} isDisabled={isDisabled} />}
        </>
    );

}