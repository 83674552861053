import React, { useEffect, useState, useRef } from "react";
import { useIssue } from '../../../context/IssueContext';
import Badge from 'react-bootstrap/Badge';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from "react-router-dom";
import { IssueSidebar, IssueList, IssueView, IssueForm } from "../issues";

function Issues(props){

  const [id, setId] = useState(null);      
  const [view, setView] = useState('list');

  const { 
    searchParams, setSearchParams,
  } = useIssue();      

  useEffect(() => {    
  }, [searchParams]);
  
  useEffect(() => {    
    if(props.match.params.id){      
      setId(props.match.params.id);
      setView('detail');
    }else{
      setId(null);
      setView('list');
    }    
  }, []);  

  const showIssueView = (id) => {
    setId(id);
    setView('detail');
    props.history.push('/issues/'+id);
  };

  const showIssueList = () => {
    setId(null);
    setView('list');    
    props.history.push('/issues');
  }

  const showIssueForm = (id) => {
    setId(id);
    setView('form');    
    props.history.push('/issues');
  } 

  return(
      <section>
        
          <div className="row row-eq-height">
            <div className="col-xl-auto d-none">
              <IssueSidebar showIssueForm={showIssueForm}  />
            </div> 

            {view == 'list' &&
              <div className="col-xl">
                <IssueList showIssueView={showIssueView} showIssueForm={showIssueForm} />
              </div>  
            }

            {view == 'detail' &&
              <div className="col-xl">
                <IssueView id={id} showIssueList={showIssueList} />
              </div>
            }

            {view == 'form' &&
              <div className="col-xl">
                <IssueForm showIssueList={showIssueList} showIssueView={showIssueView} />
              </div>
            }            
            
          </div>
        
      </section>
  );
}

export default Issues;