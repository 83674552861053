import axios from "axios";
import { TOKEN_KEY, useAuth } from '../context/hooks/useAuth';

const api = axios.create({
  // baseURL: "http://localhost/wna-helpdesk-api/public/api/wna"
  // baseURL: "https://wna-helpdesk-api.webteria.dev.br/api/wna"
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem(TOKEN_KEY); 
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(response => {
  return response;
}, err => {  
  if(err.response.status === 401 && err.response.config.url != '/auth/login'){
    window.location.href = '/';
  }    
  /*    
  return new Promise((resolve, reject) => {        
      const originalReq = err.config;
      if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest ){
        // Redirect
      }
      console.log('error');      
      return Promise.reject(err);
  });
  */
  return Promise.reject(err);
});

export default api;