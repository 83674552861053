import React, { useContext, useEffect, useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Context } from '../../context/AuthContext';
import api from '../../services/api';
import { Link } from "react-router-dom";

function HeaderNavbar(){

    const { authenticated, permissions, handleLogout } = useContext(Context);

    const logout = () => {
        handleLogout();
    }

    /*
    const loadPermissions = () => { 
        api.post('/auth/permissions')
        .then(response => {
            setPermissions(response.data);
        });
    };

    useEffect(() => {
        loadPermissions();
    }, []);
    */

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="/dashboard">
                <h2 className="mb-0">WNA</h2>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                    <Nav.Link as={Link} to="/issues">Chamados</Nav.Link>
                    {permissions.includes('full-access') && <Nav.Link as={Link} to="/locations">Unidades</Nav.Link>}
                    {permissions.includes('full-access') && <Nav.Link as={Link} to="/activities">Atividades</Nav.Link>}
                    {permissions.includes('full-access') && <Nav.Link as={Link} to="/equipaments">Equipamentos</Nav.Link>}
                    {permissions.includes('full-access') && <Nav.Link as={Link} to="/users">Usuários</Nav.Link>}
                    <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );

}

export default HeaderNavbar;