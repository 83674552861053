import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import ActivitiesMultipleSelect from '../../ui/activities-multiple-select';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

function LocationsForm(props){

  const [blocking, setBlocking] = useState(false);
  const [item, setItem] = useState(false);

  const handleActivityFilter = (value) => {
    //
  };  

  const RenderActivitiesSelect = () => {
    if(item.relationships){
      return <ActivitiesMultipleSelect label="-- Selecione as atividade --" defaultValue={item.relationships.activities} onChange={handleActivityFilter} />;
    }else{
      return <ActivitiesMultipleSelect label="-- Selecione as atividade --" defaultValue={[]} onChange={handleActivityFilter} />;
    }  
  }

  const handleSubmit = (e) => {    

    setBlocking(true);   

    const {name, activities} = e.target.elements; 

    let activities_ids = [];
    if(activities.length == undefined){
      if(activities.value){
        activities_ids.push(activities.value);
      }      
    }else{
      activities.forEach((item, index) => {
        activities_ids.push(item.value);
      });
    }

    let params = {
      name: name.value,
      activities_ids: activities_ids
    };

    let endpoint = '/locations/create';
    if(item.id){
      params.id = item.id;
      endpoint = '/locations/update';
    }    

    api.post(endpoint, params)
    .then(response => { 
        props.onHide(true);
        setBlocking(false);
    }).catch((error) => {
        setBlocking(false);
    });

    e.preventDefault();

};

  useEffect(() => {
    if(props.id !== null){
      setBlocking(true);
      api.get('/locations/show/'+props.id)
      .then(response => {     
          setBlocking(false); 
          setItem(response.data.data);          
      });   
    }else{
      setItem({});
    }
  },[props.id]);

  const onHide = () => {
    props.onHide();
  };

  return(
    
      <Modal
        {...props}
        size="lg"
        centered
        onHide={() => onHide()}
      >
        
        <BlockUi tag="div" blocking={blocking}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.id ? 'Editar unidade' : 'Nova unidade'}
              </Modal.Title>
            </Modal.Header>            
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Nome da unidade</Form.Label>
                <Form.Control name="name" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.name} />
              </Form.Group> 
              <Form.Group className="mb-3">
                <Form.Label>Atividades permitidas</Form.Label>
                <RenderActivitiesSelect />                
              </Form.Group>                           
            </Modal.Body>          
            <Modal.Footer>
              <Button type="submit">Salvar</Button>
            </Modal.Footer>
          </Form>
        </BlockUi>
        
      </Modal>
    
  );
}

export default LocationsForm;