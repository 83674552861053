import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ContractsTypesSelect from '../../ui/contracts-types-select';
import EquipamentsSelect from '../../ui/equipaments-select';

function ContractsEquipamentsForm(props){

  const [blocking, setBlocking] = useState(false);
  const [item, setItem] = useState({});

  const renderContractTypeSelect = () => {
    if(!props.id || item?.relationships?.contract_type?.id){
      return (
        <Form.Group className="mb-3">
          <Form.Label>Tipo de contrato</Form.Label>
          <ContractsTypesSelect label="-- Selecione o tipo de contrato --" onChange={handleContractTypeFilter} defaultValue={item?.relationships?.contract_type?.id} />          
        </Form.Group>
      )
    }
  };   

  const renderEquipamentsSelect = () => {
    if(!props.id || item?.relationships?.equipament?.id){
      return (
        <Form.Group className="mb-3">
          <Form.Label>Equipamento</Form.Label>
          <EquipamentsSelect label="-- Selecione o equipamento --" onChange={handleEquipamentFilter} defaultValue={item?.relationships?.equipament?.id} />          
        </Form.Group>
      )
    }
  };   

  const handleContractTypeFilter = (e) => {
  }

  const handleEquipamentFilter = (e) => {
  }

  const handleSubmit = (e) => {    

    setBlocking(true);   

    const {contract_type_id, equipament_id, ip} = e.target.elements;  
    
    let params = {
      contract_type_id: contract_type_id.value,
      equipament_id: equipament_id.value,
      ip: ip.value
    };

    let endpoint = '/contracts/add_equipament';
    params.id = props.contract_id;
    if(item.id){
      endpoint = '/contracts/update_equipament';
      params.contract_equipament_id = item.id;
    }

    api.post(endpoint, params)
    .then(response => { 
        props.onHide(true);
        setBlocking(false);
    }).catch((error) => {
        setBlocking(false);
    });
  
    e.preventDefault();

};

  useEffect(() => {
    if(props.id !== null){
      setBlocking(true);
      api.post('/contracts/show_equipament', {
        id: props.contract_id,
        contract_equipament_id: props.id
      })
      .then(response => {     
          setBlocking(false); 
          setItem(response.data.data);
      });   
    }else{
      setItem({});
    }
  },[props.id]);

  const onHide = () => {
    props.onHide();
  };

  return(
    
      <Modal
        {...props}
        size="lg"
        centered
        onHide={() => onHide()}
      >
        
        <BlockUi tag="div" blocking={blocking}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.id ? 'Editar equipamento' : 'Novo equipamento'}
              </Modal.Title>
            </Modal.Header>            
            <Modal.Body> 

              {renderContractTypeSelect()}

              {renderEquipamentsSelect()}   

              <Form.Group className="mb-3">
                <Form.Label>IP</Form.Label>
                <Form.Control name="ip" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.ip} />
              </Form.Group>                                                    
            </Modal.Body>          
            <Modal.Footer>
              <Button type="submit">Salvar</Button>
            </Modal.Footer>
          </Form>
        </BlockUi>
        
      </Modal>
    
  );
}

export default ContractsEquipamentsForm;