import Badge from 'react-bootstrap/Badge';

export default function BadgeUrgency(props){

    let urgency_title;
    let classes;

    switch (props.urgency) {
        case 1:
            urgency_title = 'Baixa';            
            classes = 'bg-white border border-warning text-warning';
            break;
        case 2:
            urgency_title = 'Média';
            classes = 'bg-white border border-warning text-warning';
            break;
        case 3:
            urgency_title = 'Alta';
            classes = 'bg-white border border-danger text-danger';
            break; 
        case 4:
            urgency_title = 'Crítica';
            classes = 'bg-danger';
            break;                                    
        default:
            urgency_title = 'Não definida';
            classes = 'light';
            break;
    }

    return (<Badge className={classes}>{urgency_title}</Badge>);
}