import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import api from '../../../services/api';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import LocationsSelect from '../../ui/locations-select';
import ActivitiesSelect from '../../ui/activities-select';
import UrgenciesSelect from '../../ui/urgencies-select';

function IssueForm(props){

    const showIssueList = () => props.showIssueList();
    const showIssueView = (id) => props.showIssueView(id);

    const [blocking, setBlocking] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [location, setLocation] = useState(null);
    const [activity, setActivity] = useState(null);
    const [urgency, setUrgency] = useState(null);
    
    const handleLocationFilter = (value) => {
        setLocation(value);
    };

    const handleActivityFilter = (value) => {
        setActivity(value);           
    };     
    
    const handleUrgencyFilter = (value) => {
        setUrgency(value);         
    };    

    const handleSubmit = (e) => {     
        setBlocking(true);   
        const {title, description} = e.target.elements; 
        api.post("/issues/create", {
            title: title.value,
            activity_id: activity,
            location_id: location,
            urgency: urgency,
            description: description.value
        }).then(response => {   
            setShowSuccessToast(true);                 
            setTimeout(function(){
                showIssueView(response.data.data.id);  
                setBlocking(false);
            }, 2000);                        
        }).catch((error) => {                       
            setShowErrorToast(true);
            setBlocking(false);
        });
        e.preventDefault();
    };

    return(
        <BlockUi tag="div" blocking={blocking} className="bg-light">

            <div className="border">
                <div className="subheader bg-white border-bottom px-3 py-3 d-flex align-items-center justify-content-between bg-light">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => {showIssueList(null)}}>
                            <svg width="8" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" class="svg-inline--fa fa-arrow-left fa-w-14 text-dark me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                            Chamados
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Novo chamado
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="p-3 py-5 py-lg-5 bg-white">
                    <div className="row">
                        <div className="col-xl-8 col-xxl-8 mx-auto">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Assunto</Form.Label>
                                    <Form.Control name="title" type="text" placeholder="Digite o assunto" />
                                </Form.Group>     

                                <Form.Group className="mb-3">
                                    <Form.Label>Unidade</Form.Label>
                                    <LocationsSelect label="-- Qualquer unidade --" onChange={handleLocationFilter} />
                                </Form.Group> 

                                <Form.Group className="mb-3">
                                    <Form.Label>Atividade</Form.Label>
                                    <ActivitiesSelect label="-- Qualquer atividade --" location={location} onChange={handleActivityFilter} />
                                </Form.Group> 

                                <Form.Group className="mb-3">
                                    <Form.Label>Urgência</Form.Label>
                                    <UrgenciesSelect label="-- Qualquer urgência --" onChange={handleUrgencyFilter} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control name="description" as="textarea" placeholder="Explique aqui o motivo do chamado" style={{ height: '150px' }} />
                                </Form.Group>                              

                                <Form.Group className="d-flex justify-content-end">
                                    <Button variant="primary" type="submit">
                                        Abrir chamado
                                    </Button>
                                </Form.Group>
                            </Form> 
                        </div>
                    </div>               
                </div> 
            </div>
            <ToastContainer className="p-3" style={{position: 'fixed',top: 0,right: 0, zIndex: 999999}}>
                <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={3000} bg="danger" autohide>
                    <Toast.Header className="bg-danger">
                        <strong className="me-auto text-white">Atenção</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body className="text-white">Houve um erro. Por favor, tente novamente.</Toast.Body>
                </Toast>
                <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={3000} bg="success" autohide>
                    <Toast.Header className="bg-success">
                        <strong className="me-auto text-white">Sucesso!</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body className="text-white">Chamado criado com sucesso!</Toast.Body>
                </Toast>                    
            </ToastContainer>              
        </BlockUi>
    );    
}

export default IssueForm;