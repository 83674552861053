import React, {useState, useEffect} from  'react';
import api from '../../services/api';
import Select from 'react-select';

export default function EquipamentsSelect(props){
    
    const [equipaments, setEquipaments] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [options, setOptions] = useState([]);

    const onChange = (option) => {        
        props.onChange(option.value);
    };

    const getEquipaments = () => {
        api.get('/equipaments/index')
        .then(response => {     
            setEquipaments(response.data.data);                   
        });         
    }

    const getOptions = () => {
        let options = [
            {value: null, label: (props.label ? props.label : '-- Selecione --')} 
        ];        
        equipaments.map((item, index) => {
            let option = {
                value: item.id,
                label: item.attributes.name
            };
            options.push(option);
        });
        setOptions(options);        
    };   

    useEffect(() => {
        getOptions();
    }, [equipaments]);   
    
    useEffect(() => {
        options.map((option, index) => {            
            if(option.value == props.defaultValue){
                setDefaultValue(option);                
            }
        });       
    }, [options]);  

    useEffect(() => {          
        getEquipaments();       
    }, []); 

    return (
        <>
            {(options && defaultValue) && <Select name="equipament_id" options={options} defaultValue={defaultValue} onChange={onChange} />}
        </>
    );

}