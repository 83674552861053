import React, {useEffect, useState} from 'react';
import api from '../../../services/api';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import RolesSelect from '../../ui/roles-select';
import LocationsSelect from '../../ui/locations-select';

function UsersForm(props){

  const [blocking, setBlocking] = useState(false);
  const [item, setItem] = useState({});
  const [role_id, setRoleId] = useState(null);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [location_id, setLocationId] = useState(null);

  const handleRole = (role_id, object) => {
    setRoleId(role_id);
    if(object.attributes.can_access_any_location == 0){
      setShowLocationSelect(true);
    }else{
      setShowLocationSelect(false);
      setLocationId(null);
    }    
  };

  const handleLocation = (location_id) => {
    setLocationId(location_id);
  };  

  const handleSubmit = (e) => {    

    setBlocking(true);   

    const {name,email} = e.target.elements;     
    let params = {
      name: name.value,
      email: email.value,
      role_id: role_id,
      location_id: location_id
    };

    let endpoint = '/users/create';
    if(item.id){
      params.id = item.id;
      endpoint = '/users/update';
    }    

    api.post(endpoint, params)
    .then(response => { 
        onHide(true);
        setBlocking(false);
    }).catch((error) => {
        setBlocking(false);
    });

    e.preventDefault();

  };

  const renderRolesSelect = () => {
    if(!props.id || item?.relationships?.role?.id){
      return (
        <Form.Group className="mb-3">
          <Form.Label>Cargo</Form.Label>
          <RolesSelect label="-- Selecione o cargo --" defaultValue={item?.relationships?.role?.id} onChange={handleRole} />
        </Form.Group>
      )
    }
  };

  const renderLocationsSelect = () => {
    if(showLocationSelect || item?.relationships?.role.attributes.can_access_any_location == 0){
      return (
        <Form.Group className="mb-3">
          <Form.Label>Unidade</Form.Label>        
          <LocationsSelect label="-- Selecione a unidade --"  defaultValue={item?.relationships?.role?.attributes.location_id} onChange={handleLocation} />
        </Form.Group>
      )
    }
  };
 
  useEffect(() => {
    if(props.id !== null){
      setBlocking(true);
      api.get('/users/show/'+props.id)
      .then(response => {     
          setBlocking(false); 
          setItem(response.data.data);
          setRoleId(response.data.data.relationships.role.id);
          setLocationId(response.data.data.relationships.role.attributes.location_id);               
      });
    }else{
      setItem({});
    }
  },[props.id]);

  useEffect(() => {
    if(item.id){
      if(item?.relationships?.role?.attributes.can_access_any_location == 0){
        setShowLocationSelect(true);
      }      
    }
  }, [item]);

  const onHide = (refresh) => {
    setShowLocationSelect(false);
    setLocationId(null);
    setRoleId(null);
    props.onHide(refresh);  
  };

  return(
    
      <Modal
        {...props}
        size="lg"
        centered
        onHide={() => onHide(false)}
      >
        
        <BlockUi tag="div" blocking={blocking}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {props.id ? 'Editar usuário' : 'Novo usuário'}
              </Modal.Title>
            </Modal.Header>            
            <Modal.Body>

              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control name="name" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.name} />
              </Form.Group> 

              <Form.Group className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control name="email" type="text" placeholder="Digite aqui" defaultValue={item?.attributes?.email} />
              </Form.Group>

              {renderRolesSelect()}   

              {renderLocationsSelect()}                                                                                          

            </Modal.Body>          
            <Modal.Footer>
              <Button type="submit">Salvar</Button>
            </Modal.Footer>
          </Form>
        </BlockUi>
        
      </Modal>
    
  );
}

export default UsersForm;