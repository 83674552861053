import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

const IssueContext = createContext();

function IssueProvider({ children }) {
  
  const [issues, setIssues] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [pagination, setPagination] = useState(null);
  const [totalIssues, setTotalIssues] = useState(null);
  const [currentLink, setCurrentLink] = useState(null);
  const [meta, setMeta] = useState(null);  

  return (
    <IssueContext.Provider value={{ 
      issues, setIssues,
      searchParams, setSearchParams,
      pagination, setPagination,
      totalIssues, setTotalIssues,
      meta, setMeta
    }}>
      {children}
    </IssueContext.Provider>
  );

}

const useIssue = () => useContext(IssueContext);

export { IssueContext, IssueProvider, useIssue };