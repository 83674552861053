import React, { useEffect, useState, useContext } from 'react';
import api from '../../../services/api';
import { Context } from '../../../context/AuthContext';
import { useIssue } from "../../../context/IssueContext";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import BadgeUrgency from '../../ui/badge-urgency';
import LocationsSelect from '../../ui/locations-select';
import StatusSelect from '../../ui/status-select';
import ActivitiesSelect from '../../ui/activities-select';
import UrgenciesSelect from '../../ui/urgencies-select';

const IssueList = React.forwardRef((props, ref) => {

    const { permissions } = useContext(Context);

    const [blocking, setBlocking] = useState(false); 

    const { 
        issues, setIssues,
        searchParams, setSearchParams,
        pagination, setPagination,
        totalIssues, setTotalIssues,
        meta, setMeta
    } = useIssue();    

    const showIssueView = (id) => props.showIssueView(id);

    const search = (e) => {  
        const {term} = e.target.elements; 
        let newSearchParams = {};
        newSearchParams.term = term.value;        
        setNewSearchParams(newSearchParams); 
        e.preventDefault();
      };    

    const handleStatusFilter = (value) => {
        let newSearchParams = {};
        newSearchParams.current_status_id = value;        
        setNewSearchParams(newSearchParams);        
    }; 

    const handleLocationFilter = (value) => {
        let newSearchParams = {};
        newSearchParams.location_id = value;        
        setNewSearchParams(newSearchParams); 
    };

    const handleActivityFilter = (value) => {
        let newSearchParams = {};
        newSearchParams.activity_id = value;        
        setNewSearchParams(newSearchParams);          
    };     
    
    const handleUrgencyFilter = (value) => {
        let newSearchParams = {};
        newSearchParams.urgency = value;   
        setNewSearchParams(newSearchParams);  
    };

    const setNewSearchParams = (newSearchParams) => {        
        setSearchParams({...searchParams, ...newSearchParams});  
    };
    
    const loadIssues = () => {
        setBlocking(true);
        let params = {};
        let page = {page: 1};
        if(meta?.current_page){
            page = {page: meta.current_page};           
        }
        params = {...searchParams, ...page};
        api.get('/issues/index', {
            params: params
        }).then(response => {     
            setBlocking(false); 
            setIssues(response.data.data);
            setPagination(response.data.links);
            setTotalIssues(response.data.meta.total);         
            setMeta(response.data.meta);                  
        });    
    };   
    
    const paginationClick = (link) => {   
        setBlocking(true); 
        api.get(link, {
            params: {}
        }).then(response => {   
            setBlocking(false);          
            setIssues(response.data.data);            
            setPagination(response.data.links);
            setTotalIssues(response.data.meta.total);        
            setMeta(response.data.meta);                 
        });
    };

    useEffect(() => {      
        loadIssues();               
    }, [searchParams]); 

    return(
        <BlockUi tag="div" blocking={blocking}> 

            <div className="border">

                <div className="subheader bg-white border-bottom px-3 py-3 d-flex align-items-center justify-content-between bg-light">
                    <h2 className="h5 mb-0">Chamados</h2>
                    {(permissions.includes('full-access') || permissions.includes('edit-issue')) &&
                        <div className="ms-3">
                            <a href="#" className="btn btn-outline-primary" onClick={() => props.showIssueForm(null)}>+ Novo chamado</a>
                        </div>
                    }
                </div>

                <div>
                    <div className="card p-3 border-0 border-bottom rounded-0 bg-white">
                        <div className="row align-items-center">
                            <div className="col-xl col-xxl mb-2 mb-lg-0">
                                <Form onSubmit={search}>
                                    <InputGroup>
                                        <FormControl name="term" className="border-gray" placeholder="Digite o termo..." />
                                        <Button className="bg-white" style={{border: '1px solid #ced4da', borderLeft: 0}} type="submit">
                                            <svg width="12" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16 text-dark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                                        </Button>
                                    </InputGroup>  
                                </Form>
                            </div>
                            <div className="col-xl col-xxl mb-2 mb-lg-0">
                                <LocationsSelect label="-- Qualquer unidade --" onChange={handleLocationFilter} />
                            </div>                        
                            <div className="col-xl col-xxl mb-2 mb-lg-0">
                                <ActivitiesSelect label="-- Qualquer atividade --" location={searchParams.location_id} onChange={handleActivityFilter} />
                            </div>
                            <div className="col-xl col-xxl mb-2 mb-lg-0">
                                <StatusSelect label="-- Qualquer status --" onChange={handleStatusFilter} />
                            </div>                            
                            <div className="col-xl col-xxl mb-2 mb-lg-0">
                                <UrgenciesSelect label="-- Qualquer urgência --" onChange={handleUrgencyFilter} />
                            </div>                                 
                        </div>
                    </div>
                </div>                
                <div>
                    <div className="card p-2 border-0 rounded-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="small ps-2">
                                {totalIssues > 0 ? meta?.from+' - '+meta?.to+' de '+meta?.total : 'Nenhum resultado encontrado.'}                            
                            </div>
                            <div className="d-flex small align-items-center width-auto">
                                <div className="pe-3">
                                    Página {meta?.current_page} de {meta?.last_page}
                                </div>
                                <Pagination size="sm" className="mb-0">  
                                    {pagination?.first != null &&                               
                                        <Pagination.First onClick={() => paginationClick(pagination?.first)} />
                                    }
                                    {pagination?.prev != null &&
                                        <Pagination.Prev onClick={() => paginationClick(pagination?.prev)} />
                                    }
                                    {pagination?.next != null &&
                                        <Pagination.Next onClick={() => paginationClick(pagination?.next)} />                            
                                    }
                                    {pagination?.last != null &&
                                        <Pagination.Last onClick={() => paginationClick(pagination?.last)} />
                                    }
                                </Pagination>
                            </div>                      
                        </div>
                    </div>
                </div>                
                <div>
                    <div className="card px-3 py-2 border-0 border-bottom rounded-0 d-none">
                        <div className="row align-items-center">
                            <div className="col-xl-3">
                                <span className="small text-uppercase">Solicitante</span>
                            </div>
                            <div className="col-xl-2">
                                <span className="small text-uppercase">Atividade</span>
                            </div>
                            <div className="col-xl-2">
                                <span className="small text-uppercase">Assunto</span>
                            </div>                        
                            <div className="col-xl-2">
                                <span className="small text-uppercase">Atendente</span>
                            </div>
                            <div className="col-xl-2 text-center">
                                <span className="small text-uppercase">Status</span>
                            </div>
                            <div className="col-xl-1 text-center">
                                <span className="small text-uppercase">Data</span>
                            </div>                                                                                                              
                        </div>
                    </div>
                </div>   
            </div>             
            <div className="row g-3 mt-2 row-eq-height">
                {issues.length > 0 && issues.map((issue, i) => (
                    <div className="col-lg-4">
                        <div className="card h-100 border-0 shadow-sm p-3" onClick={() => {showIssueView(issue.id)}}>
                            <div className="row">
                                <div className="col-4">
                                    #{issue.id}
                                </div>
                                <div className="col-8 d-flex justify-content-end">
                                    <BadgeUrgency urgency={issue.attributes.urgency} />
                                    {issue.relationships?.status && 
                                        <Badge bg={issue.relationships?.status.attributes.css_class} className="ms-1">{issue.relationships?.status.attributes.name}</Badge>
                                    }
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <span className="font-weight-bold">
                                        {issue.relationships.author.attributes.name}
                                    </span>
                                </div>
                                <div className="col-12">
                                    {issue.attributes.title}
                                </div> 
                                <div className="col-12 mt-4">
                                    <div className="text-muted small">{issue.relationships.activity.attributes.name}</div>
                                    <div className="text-muted small">{issue.relationships.location.attributes.name}</div>
                                </div>                                                                
                            </div>
                        </div>
                    </div>
                ))}
            </div>       
        </BlockUi>
    );    
});

export default IssueList;